import Loader from "./loader";
import MenuEvents from "./menu-events";

// Load application
window.onload = () => {
  /**
   * Menu and Window events
   */
  MenuEvents.setBurgerMenuWhenMobile();
  MenuEvents.scrollPageEvent();
  MenuEvents.smoothLinkEvent();

  Loader.stopLoading();
};
